<template>
    <h3 class="UserDataTableIndex"><span>Gestione tesserati</span></h3>
    <!-- Inizio sezione nuova -->
    <div class="AddNewMemberBox">
    <div class="AddNewMemberField ToggleBox" ref="toggle">
    <fieldset>
        <legend>Dati nuovo tesserato</legend>
        <div class="FormRow">
            <div class="FormBox50">
                <label>Nome</label>
                <input type="text" id="NOME_TESSERATO" name="" placeholder="Nome" v-model="this.newNome">
            </div>
            <div class="FormBox50">
                <label>Cognome</label>
                <input type="text" id="COGNOME_TESSERATO" name="" placeholder="Cognome" v-model="this.newCognome">
            </div>
        </div>
        <div class="FormRow">
            <div class="FormBox50">
                <label>Data di nascita</label>
                <input type="date" id="DATA_NASCITA_TESSERATO" name="" placeholder="" v-model="this.newDate">
            </div>
            <div class="FormBox50">
                <label>Anno</label>
                <select class="CustomSelect" id="CATEGORIA_TESSERATO" name="" v-model="this.newCategoria">
                    <option>Seleziona l'anno</option>
                    <option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
                </select>
            </div>
            <div class="FormBox50">
                <label>Società</label>
                <select class="CustomSelect" id="SOCIETA_TESSERATO" v-model="this.newSocieta">
					<option selected="selected" value="">Seleziona la società</option>
					<option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
				</select>
            </div>
        </div>
        <div class="FormRow">
            <fieldset v-if="this.newEta < 18">
                <legend>Dati genitore</legend>
                <div class="FormRow">
                    <div class="FormBox50">
                        <label>Nome</label>
                        <input type="text" id="NOME_GENITORE" name="NOME_GENITORE_2" placeholder="" v-model="this.NewNomeGenitore">
                    </div>
                    <div class="FormBox50">
                        <label>Cognome</label>
                        <input type="text" id="COGNOME_GENITORE" name="COGNOME_GENITORE" placeholder="" v-model="this.NewCognomeGenitore">
                    </div>
                </div>
                <div class="FormRow">
                    <div class="FormBox50">
                        <label>Data di nascita</label>
                        <input type="date" id="DATA_NASCITA_GENITORE" name="DATA_NASCITA_GENITORE" placeholder="" v-model="this.NewDtNacitaGenitore">
                    </div>
                    <!--<div class="FormBox50">
                        <label>Telefono</label>
                        <input type="text" id="TELEFONO_GENITORE" name="TELEFONO_GENITORE" placeholder="" v-model="this.NewTelefonoGenitore">
                    </div>-->
                </div>
            </fieldset>
            <div class="FormBox50 TwinButtons">
                <div class="Button">
                    <input type="button" value="Annulla" @click="this.closeToggle">
                </div>
                <div class="Button">
                    <input type="button" value="Aggiungi" @click="this.addTesserato">
                </div>
            </div>
        </div>
        <div class="WhiteSpace">&nbsp;</div>
    </fieldset>
    </div>
    <div class="AddNewMemberOpenButton ToggleButton"><span>Aggiungi nuovo tesserato</span></div>
    </div>
    
    <div v-if="this.isReady" class="ProfileMemberTable">
        <table class="PMMainTable" width="100%" border="0" cellspacing="0" cellpadding="0" >
            <tbody>
                <tr>
                    <th scope="col" class="PMTName">Nome</th>
                    <th scope="col" class="PMTSurName">Cognome</th>
                    <th scope="col" class="PMTBirthDate">Data di nascita</th>
                    <th scope="col" class="PMTCategory">Anno</th>
                    <th scope="col" class="PMTClub">Società</th>
                    <th scope="col" class="PMTState">Stato tesserato</th>
                    <th scope="col" class="PMTParents">Dati Genitore</th>
                    <th scope="col" class="PMTModify">&nbsp;</th>
                    <th scope="col" class="PMTClear">&nbsp;</th>
                </tr>
                <!-- Inizio repeater -->
                <RowTesserati
                    v-for="tesserato in this.listaTesserati" :key="tesserato.NomeTesserato+tesserato.CognomeTesserato+tesserato.DataNascitaTesserato+tesserato.CodSocieta"
                    :tesserato="tesserato"
                    @UpdateList="this.getTesserati"
                />
                <!-- Fine repeater -->
            </tbody>
        </table>
    </div>
    
</template>
<script>
import RowTesserati from "@/components/profile/RowTesserati.vue";
import {apiMixin,dateMixin} from "@/mixin.js";

export default {
    name:"PannTesserati",
    data(){
        return {
            tesseratoUnico: null,
            isReady: false,
            listaTesserati:[],
            newNome: "",
            newCognome: "",
            newDate: "",
            newCategoria: "",
            newSocieta: "",
            NewNomeGenitore:"",
            NewCognomeGenitore:"",
            NewDtNacitaGenitore: "",
            //NewTelefonoGenitore: "",
            newEta: 18,
            listaCategorie: []
        }   
    },
    watch:{
        newDate: function(newValue){
            var today = new Date();
			var birthDate = new Date(newValue);
			var age = (today.getFullYear() - birthDate.getFullYear());
            this.newEta = age; 
        },
        newNome: function(newValue){
            this.inputError(newValue, 'NOME_TESSERATO');	
        },
        newCognome: function(newValue){
            this.inputError(newValue, 'COGNOME_TESSERATO');	
        },
        newCategoria: function(newValue){
            this.inputError(newValue, 'CATEGORIA_TESSERATO');	
        },
        newSocieta: function(newValue){
            this.inputError(newValue, 'SOCIETA_TESSERATO');	
        },
        NewNomeGenitore: function(newValue){
            this.inputError(newValue, 'NOME_GENITORE');	
        },
        NewCognomeGenitore: function(newValue){
            this.inputError(newValue, 'COGNOME_GENITORE');	
        },
        NewDtNacitaGenitore: function(newValue){
            this.inputError(newValue, 'DATA_NASCITA_GENITORE');	
        }/*
        NewTelefonoGenitore: function(newValue){
            this.inputError(newValue, 'TELEFONO_GENITORE');	
        }*/
    },
    computed:{
        listaSocieta: function(){
			return this.$store.getters.getSocieta;
		},
    },
    mixins:[apiMixin,dateMixin],
    components:{
        RowTesserati
    },
    mounted: function(){
        $(".ToggleBox").hide();
		$(".ToggleButton").addClass('Open');
		$(".ToggleButton").click(function(){
			$(this).parent().find(".ToggleBox").slideToggle('fast');
			$(this).toggleClass('Open').toggleClass('Close');
		}); 

        this.listaTesserati = this.getTesserati();
        this.listaCategorie = this.$store.getters.getCategorie;
        $(".MemberParents").hide();
        $(".TableToggleButton").addClass('Open');
        $(".TableToggleButton").click(function(){
            $(this).parent().parent().parent().find(".MemberParents").slideToggle('fast');
            $(this).toggleClass('Open').toggleClass('Close');
            this.clearBorder();
        });
    },
    methods:{
        closeToggle: function(){
            this.clearData();
            $(this.$refs.toggle).parent().find(".ToggleBox").slideToggle('fast');
			$(this.$refs.toggle).toggleClass('Open').toggleClass('Close');
        },
        clearData: function(){
            this.newNome= "";
            this.newCognome= "";
            this.newDate= "";
            this.newCategoria= "";
            this.newSocieta= "";
            this.NewNomeGenitore="";
            this.NewCognomeGenitore="";
            this.NewDtNacitaGenitore= "";
            //this.NewTelefonoGenitore= "";
            this.newEta= 18;
        },
        updateUtente: function(){
            let utente = this.$store.getters.getAnagraficaUtente;
            utente.Tesserati = this.listaTesserati;
            if(this.listaTesserati?.length == 1){
                this.$store.commit('setSelectedSociety',this.listaTesserati[0].CodiceSocieta);
            }
            this.$store.commit('setUtente',utente);
            this.$store.dispatch({
                type: 'getCarrello',
                token: this.$store.getters.getToken,
                shop: this.$store.getters.getShop
            }).then((data) =>{
                this.$store.dispatch({
                    type: 'setLoader',
                    value: false
                });
                if(this.$store.getters.getCarrello) {
                    this.Testata = this.$store.getters.getCarrello.Testata;
                }
                else{
                    this.Testata = {};
                }
            });
        },
        preparePayload: function(){
            return {
                NOME_TESSERATO: this.newNome,
                COGNOME_TESSERATO: this.newCognome,
                DATA_NASCITA_TESSERATO: this.formatDateNoOre(this.newDate),
                SOCIETA_TESSERATO: this.newSocieta,
                CATEGORIA_TESSERATO: this.newCategoria,
                NOME_GENITORE: this.NewNomeGenitore,
                COGNOME_GENITORE: this.NewCognomeGenitore,
                DATA_NASCITA_GENITORE: this.NewDtNacitaGenitore
                //TELEFONO_GENITORE: this.NewTelefonoGenitore 
            }
        },
        getTesserati: function(){
            this.doApiCall("get","b2c/Tesserato/GetTesserati",this.$store.getters.getToken).then(
                (data) =>{
                    if(data){
                        this.listaTesserati = data;
                        this.isReady = true;
                        this.updateUtente();
                    }
                }
            );
        },
        addTesserato: function(){
            if(!this.checkForm()){
				return;
			}
            let payload = this.preparePayload();
            this.isReady = false;
            this.doApiCall("post","b2c/Tesserato/AddTesserato",this.$store.getters.getToken, null, payload).then(
                (data) =>{
                    if(data.StatusCode == 200){
                        $(".ToggleBox").hide();
                        $(".ToggleButton").addClass('Open');
                        this.clearData();
                    }else{
                        if(data.Data == "Tesserato già presente"){
                            this.$toast.error("Tesserato già presente!");
                        }
                    }
                    this.getTesserati();
                }
            );
        },
        inputError: function(val, id){
			if(val){
				document.getElementById(id).classList.remove('errorBorder');
			}
			else{
				document.getElementById(id).classList.add('errorBorder');
			}
		},
        checkForm: function () {
            debugger;
			let check = true;
			if(!this.newNome){
				document.getElementById('NOME_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.newCognome){
				document.getElementById('COGNOME_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.newDate){
				document.getElementById('DATA_NASCITA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.newSocieta){
				document.getElementById('SOCIETA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}	
			if(!this.newCategoria || this.newCategoria == "Seleziona l'anno"){
				document.getElementById('CATEGORIA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			// Controlli sui dati del genitore se il tesserato è minorenne
			if(this.newEta < 18){
                if(!this.NewNomeGenitore){
                    document.getElementById('NOME_GENITORE').classList.add('errorBorder');
                    check = check && false;
                }
                if(!this.NewCognomeGenitore){
                    document.getElementById('COGNOME_GENITORE').classList.add('errorBorder');
                    check = check && false;
                }
                if(!this.NewDtNacitaGenitore){
                    document.getElementById('DATA_NASCITA_GENITORE').classList.add('errorBorder');
                    check = check && false;
                }/*
                if(!this.NewTelefonoGenitore){
                    document.getElementById('TELEFONO_GENITORE').classList.add('errorBorder');
                    check = check && false;
                }*/
			}
			return check;
		},
        clearBorder: function(){
            document.getElementById('NOME_TESSERATO').classList.remove('errorBorder');
            document.getElementById('COGNOME_TESSERATO').classList.remove('errorBorder');
            document.getElementById('DATA_NASCITA_TESSERATO').classList.remove('errorBorder');
            document.getElementById('SOCIETA_TESSERATO').classList.remove('errorBorder');
            document.getElementById('CATEGORIA_TESSERATO').classList.remove('errorBorder');
        }
    }
}
</script>

<style>
.errorBorder{
	border: 2px solid #ff0000 !important;
}
</style>