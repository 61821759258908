<template>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" name="popUpModificaTesserato">
        <div class="BtnClose">
            <span style="cursor:pointer"><mdi-close @click="this.closeModal()" ></mdi-close></span>
        </div>
        <div class="Box" v-if="this.isReady">
            <h1>Modifica dati tesserato</h1>
            <form class="Form">
                <fieldset>
                    <legend>Dati tesserato</legend>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Nome</label>
                            <input type="text" v-model="this.NomeTesserato">
                        </div>
                        <div class="FormBox50">
                            <label>Cognome</label>
                            <input type="text" v-model="this.CognomeTesserato">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Data di nascita</label>
                            <input type="date" v-model="this.DataNascitaTesserato"> 
                        </div>
                        <div class="FormBox50">
                            <label>Società</label>
                            <select class="CustomSelect" v-model="this.Societa">
                                <option selected="selected" value="">Seleziona la società</option>
                                <option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
                            </select>
                        </div>
                        <div class="FormBox50">
                            <label>Anno</label>
                            <select class="CustomSelect" v-model="this.CodiceCategoriaTesserato">
                                <option>Seleziona l'anno</option>
                                <option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
                            </select>
                        </div>
                    </div>

                </fieldset>

                <fieldset>
                    <legend>Dati genitore</legend>
                    <div v-if="!this.isMaggiorenne" class="FormRow">
                        <div class="FormBox50">
                            <label>Nome</label>
                            <input type="text" placeholder="Giovanni" v-model="this.NomeGenitore">
                        </div>
                        <div class="FormBox50">
                            <label>Cognome</label>
                            <input type="text" placeholder="Baggio" v-model="this.CognomeGenitore">
                        </div>
                    </div>
                    <div v-if="!this.isMaggiorenne" class="FormRow">
                        <div class="FormBox50">
                            <label>Data di nascita</label>
                            <input type="date" v-model="this.DataNascitaGenitore">
                        </div>
                        <!--<div class="FormBox50">
                            <label>Telefono</label>
                            <input type="text" placeholder="123 456 789" v-model="this.TelefonoGenitore">
                        </div>-->
                    </div>
                    <div class="WhiteSpace">&nbsp;</div>
                    <div class="FormRow">
                        <div class="FormBoxWide TwinButtons">
                            <div class="Button">
                                <input type="button" value="Annulla" @click="this.closeModal">
                            </div>
                            <div class="Button">
                                <input type="button" value="Salva" @click="this.addTesserato">
                            </div>
                        </div>
                    </div>
                    <div class="WhiteSpace">&nbsp;</div>
                </fieldset>
            </form>
        </div>
    </vue-final-modal>
</template>
<script>
import MdiClose from "@/components/modals/MdiClose.vue";
import {dateMixin,apiMixin} from "@/mixin.js";

export default {
    name:"ModalModTesserato",
    data(){
        return{
            isReady: false,
            isMaggiorenne: true,

            // Dati Tesserato
            NomeTesserato: "",
            CognomeTesserato: "",
            DataNascitaTesserato: "",
            CodiceCategoriaTesserato: "",
            Societa:"",
            NomeGenitore: "",
            CognomeGenitore:"",
            DataNascitaGenitore:"",
            //TelefonoGenitore:"",
            showModal:null
        } 
    },
    mixins:[dateMixin,apiMixin],
    computed:{
        listaSocieta: function(){
			return this.$store.getters.getSocieta;
		},
        listaCategorie: function(){
            return this.$store.getters.getCategorie;
        }
    },
    watch:{
        modifyObject: function(newValues){
            if(newValues != null){
                this.isReady = true;
                this.NomeTesserato = this.modifyObject.NomeTesserato;
                this.CognomeTesserato = this.modifyObject.CognomeTesserato;
                this.DataNascitaTesserato = this.formatDateNoOreJQuery(this.modifyObject.DataNascitaTesserato);
                this.CodiceCategoriaTesserato = this.modifyObject.CodiceCategoriaTesserato;
                this.Societa = this.modifyObject.CodiceSocieta;
                this.NomeGenitore = this.modifyObject.NomeGenitore;
                this.CognomeGenitore = this.modifyObject.CognomeGenitore;
                this.DataNascitaGenitore = new Date(this.modifyObject.DataNascitaGenitore).getFullYear() != 1 ? this.formatDateNoOreJQuery(this.modifyObject.DataNascitaGenitore) : null;
                //this.TelefonoGenitore = this.modifyObject.TelefonoGenitore;
            }
        },
        DataNascitaTesserato: function(){
            let age = this.calcolaEta(this.DataNascitaTesserato);
            age < 18 ? this.isMaggiorenne = false : this.isMaggiorenne = true;
        }
    },
    components:{
        MdiClose,
    },
    props:{
        //showModal:Boolean,
        modifyObject: Object
    },
    emits:["UpdateList"],
    methods:{
        calcolaEta: function(val){
			var today = new Date();
			var birthDate = new Date(val);
			var age = (today.getFullYear() - birthDate.getFullYear());
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
        closeModal: function(){
            this.$vfm.hide('popUpModificaTesserato');
        },
        preparePayload: function(){
            return {
                NOME_TESSERATO: this.NomeTesserato,
                COGNOME_TESSERATO: this.CognomeTesserato,
                DATA_NASCITA_TESSERATO: this.formatDateNoOre(this.DataNascitaTesserato),
                SOCIETA_TESSERATO: this.Societa,
                NOME_GENITORE: this.NomeGenitore,
                COGNOME_GENITORE: this.CognomeGenitore,
                DATA_NASCITA_GENITORE: new Date(this.DataNascitaGenitore).getFullYear() != '' ? new Date(this.DataNascitaGenitore) : null,
                USERID: this.modifyObject.CodiceUtente,
                K2_RIGA: this.modifyObject.k2Riga,
                CATEGORIA_TESSERATO: this.CodiceCategoriaTesserato
            }
        },
        addTesserato: function(){
            let payload = this.preparePayload();
            this.doApiCall("post","b2c/Tesserato/ModificaTesserato",this.$store.getters.getToken, null, payload).then(
                (data) =>{
                    if(data){
                        if(data.StatusCode == 200){
                            this.$emit("UpdateList");
                            this.closeModal();
                            this.$toast.success("Moifiche salvate con successo", {position:"top-right"});
                        }else{
                            this.$toast.error(data.Data, {position:"top-right"});
                            this.closeModal();
                        }
                    }
                }
            );
            
        }
    }
}
</script>
<style>

.modal-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.modal-content {
    display: flex !important;
    flex-direction: column !important;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 0.25rem !important;
    background: #fff !important;
    width: 800px !important;
}

</style>