<template>
    <tr>
        <td colspan="9">
            <table class="PMSubTable" width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td class="PMTName">{{this.tesserato.NomeTesserato}}</td>
                        <td class="PMTSurName">{{this.tesserato.CognomeTesserato}}</td>
                        <td class="PMTBirthDate">{{this.formatDateNoOre(this.tesserato.DataNascitaTesserato)}}</td>
                        <td class="PMTCategory">{{this.categoriaDescr}}</td>
                        <td class="PMTClub">{{this.societaDescr}}</td>
                        <td class="PMTState">{{this.tesserato.Attivo == 1 ? 'Attivo' : 'Non Attivo'}}</td>
                        <td v-if="!this.maggiorenne" class="PMTParents"><div class="TableToggleButton" @click="this.openRow"><span>Visualizza</span></div></td>
                        <td v-else class="PMTParents"><div><span>/</span></div></td>
                        <td class="PMTModify">
                            <div class="Button Stroked Small"><a @click="this.openPopUp(true,false)">Modifica</a></div>
                        </td>
                        <td class="PMTClear" @click="this.openPopUp(false,true)"><a><img src="/images/DeleteIcon.png" srcset="/images/DeleteIconRetina.png 2x" alt="Rimuovi"/></a></td>
                    </tr>
                    <tr class="MemberParents" ref="riga">
                        <td colspan="9">
                            <table class="PMSubSubTable" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td colspan="4"><h4>Dati Genitore</h4></td>
                                    </tr>
                                    <tr>
                                        <td><i>Nome</i><span>{{tesserato.NomeGenitore}}</span></td>
                                        <td><i>Cognome</i><span>{{tesserato.CognomeGenitore}}</span></td>
                                        <td><i>Data di Nascita</i><span>{{this.formatDateNoOre(tesserato.DataNascitaGenitore)}}</span></td>
                                        <!--<td><i>Telefono</i><span>{{tesserato.TelefonoGenitore}}</span></td>-->
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
    <ModalModTesserato
        v-model="this.showModalMod"
        :modifyObject="this.modifyObject"
        @UpdateList="this.propagateEvent()"
    />
    <ModalDelete
        v-model="this.showModalDel"
        @ok-delete="this.DeleteTesserato"
    />
</template>
<script>
import {dateMixin, apiMixin} from "@/mixin.js";
import ModalModTesserato from "@/components/modals/ModalModTesserato.vue";
import ModalDelete from '@/components/modals/ModalDelete.vue';

export default {
    name:"RowTesserati",
    data(){
        return{
            showModalMod: false,
            showModalDel: false,
            modifyObject: null
        }
    },
    props:{
        tesserato: Object
    },
    components:{
        ModalModTesserato,
        ModalDelete
    },
    emits:["UpdateList"],
    mixins:[dateMixin,apiMixin],
    computed:{
        categoriaDescr: function(){
            let catList = this.$store.getters.getCategorie;
            let idx = -1;
            if(catList != null){
                idx = catList.findIndex(x =>{ return x.Codice == this.tesserato.CodiceCategoriaTesserato});
            }
            return  idx > -1 ? catList[idx].Descrizione : "-";
        },
        societaDescr: function(){
            let socList = this.$store.getters.getSocieta;
            let idx = -1;
            if(socList != null){
                idx = socList.findIndex(x =>{ return x.CodiceAzienda == this.tesserato.CodiceSocieta});
            }
            return  idx > -1 ? socList[idx].Descrizione : "-";
        },
        maggiorenne: function(){
            let dt = new Date().getFullYear();
            let dtTesserato = new Date(this.tesserato.DataNascitaTesserato).getFullYear();
            if((dt - dtTesserato) < 18){
                return false;
            }else{
                return true;
            }
        }
    },
    mounted:function(){
        $(".MemberParents").hide();
        $(".TableToggleButton").addClass('Open');
    },
    methods:{
        openRow: function(){
            $(this.$refs.riga).parent().parent().parent().find(".MemberParents").slideToggle('fast');
            $(this.$refs.riga).toggleClass('Open').toggleClass('Close');
        },
        openPopUp: function(mod,del){
            this.showModalMod = mod;
            this.showModalDel = del;

            if(mod){
                this.modifyObject = null;
                this.modifyObject = this.tesserato;
            }
        },
        propagateEvent: function(){
            this.$emit("UpdateList");
        },
        preparePayload: function(){
            return{
                USERID: this.tesserato.CodiceUtente,
                K2_RIGA: this.tesserato.k2Riga
            };
        },
        DeleteTesserato: function(){
            let payload = this.preparePayload();
            this.doApiCall("post","/b2c/Tesserato/DeleteTesserato", this.$store.getters.getToken, null, payload).then(
                (data) =>{
                    if(data){
                        if(data.StatusCode ==200){
                            this.$emit("UpdateList");
                            this.$toast.success("Tesserato rimosso con successo", {position:"top-right"});
                        }else{
                            this.$toast.error("Qualcosa è andato storto", {position:"top-right"});
                        }
                    }
                }
            );
        }
    }
}
</script>